import axios from "axios";
import DeviceDetector from "device-detector-js";
import QRCode from "qrcode";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Buffer = require("buffer/").Buffer;

import { MOBILE_BREAKPOINT } from "../../../globalConstants";
import { ReturnStatusComposite } from "../index";
import { clearLoadingIndicator, goToPage, setToken, showLoadingIndicator } from "../../../redux/app/actions";
import { reset } from "../../../redux/customer/actions";
import { DropoffMethodIDs } from "../../../redux/customer/types";
import { AnalyticCategories, ReturnStartedAndReturnStatusPageActions } from "../../../types/Analytics";
import { DataCyStrings } from "../../../types/DataCyStrings";
import { LabelType } from "../../../types/LabelType";
import { ReturnStatus } from "../../../types/ReturnStatus";
import { getTextColorFromBackgroundBrightness, handleEnterKeyPressOnClickHandlers } from "../../../utility";
import ga from "../../../utility/GAEmitter";
import getTranslator from "../../../utility/getTranslator";
import useSelector from "../../../utility/useTypedSelector";
import { useViewport } from "../../../utility/useViewport";

import PrimaryButton from "../../../components/Button/PrimaryButton";

import EmailModal from "../../../components/EmailModal";
import ExpressCodeApplePassButton from "../../../components/ExpressCodeApplePassButton";
import { defaultLoadingSymbol } from "../../../components/LoadingIndicator";
import { SVG } from "../../../components/svg";

import {
  $Body,
  $ButtonContents,
  $ConfirmationDetails,
  $ConfirmationDetailsHeader,
  $ConfirmationDetailsLink,
  $DisclaimerLocationMilesThreshold,
  $HappyReturnsBanner,
  $HappyReturnsLogo,
  $Header,
  $NextStepsCard,
  $PrimaryDetails,
  $PrimaryDetailsLeftColumn,
  $PrimaryDetailsSingleColumn,
  $QRCard,
  $QRCardText,
  $QRCode,
} from "./styles";

// For explicit layout desktop-mobile layout differences, use: `useViewport()` and `MOBILE_BREAKPOINT`. See
// `PrivacyFooter` component for usage example.

const getDate = (timestamp?: string): Date | null => {
  if (!timestamp) {
    return null;
  }
  return new Date(timestamp);
};

const isAppleDevice = (): boolean => {
  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator.userAgent);
  return device.device?.brand === "Apple";
};

const isRetailerStoreApplePassRetailer = (retailerID:string | undefined): boolean => {
  if (retailerID === undefined) {
    return false;
  }
  return retailerID === "happy-returns-flow";
}

const Header = ({
  deliveryDate,
  departureDate,
  dropoffMethodID,
  expiryDate,
  returnlessRefund,
  returnStatus,
}: ReturnStatusComposite) => {
  const { t } = getTranslator("NextStepsCard")();
  const deliveryDateObject = getDate(deliveryDate);
  const departureDateObject = getDate(departureDate);
  const expiryDateObject = getDate(expiryDate);

  if (returnlessRefund) {
    return <$Header data-cy={DataCyStrings.nextStepsHeaderReturnless}>{t("headerReturnless")}</$Header>;
  }
  if (returnStatus === ReturnStatus.expired && expiryDateObject) {
    return (
      <$Header data-cy={DataCyStrings.nextStepsHeaderExpired}>
        {t("headerExpired", {
          month: t(`month.${expiryDateObject.getMonth()}`),
          date: expiryDateObject.getDate(),
          year: expiryDateObject.getFullYear(),
        })}
      </$Header>
    );
  }
  if (deliveryDateObject) {
    return (
      <$Header data-cy={DataCyStrings.nextStepsHeaderDelivered}>
        {t("headerDelivered", {
          month: t(`month.${deliveryDateObject.getMonth()}`),
          date: deliveryDateObject.getDate(),
          year: deliveryDateObject.getFullYear(),
        })}
      </$Header>
    );
  }
  if (departureDateObject) {
    return (
      <$Header data-cy={DataCyStrings.nextStepsHeaderDeparted}>
        {t("headerDeparted", {
          month: t(`month.${departureDateObject.getMonth()}`),
          date: departureDateObject.getDate(),
          year: departureDateObject.getFullYear(),
        })}
      </$Header>
    );
  }
  if (returnStatus === ReturnStatus.started && expiryDateObject) {
    let key = "headerStartedReturnBar";
    let dataCy = DataCyStrings.nextStepsHeaderStartedReturnBar;
    if (dropoffMethodID === DropoffMethodIDs.retailerStore) {
      key = "headerStartedInStore";
      dataCy = DataCyStrings.nextStepsHeaderStartedInStore;
    } else if (dropoffMethodID === DropoffMethodIDs.mail || dropoffMethodID === DropoffMethodIDs.mailShopperProvided) {
      key = "headerStartedMail";
      dataCy = DataCyStrings.nextStepsHeaderStartedMail;
    }
    return (
      <$Header data-cy={dataCy}>
        {t(key, {
          month: t(`month.${expiryDateObject.getMonth()}`),
          date: expiryDateObject.getDate(),
          year: expiryDateObject.getFullYear(),
        })}
      </$Header>
    );
  }

  return <></>;
};

const Body = ({
  deliveryDate,
  departureDate,
  dropoffMethodID,
  retailerName,
  returnlessRefund,
  returnStatus,
  selectedLabelType,
}: ReturnStatusComposite) => {
  const { t } = getTranslator("NextStepsCard")();
  const deliveryDateObject = getDate(deliveryDate);
  const departureDateObject = getDate(departureDate);

  if (returnlessRefund) {
    return <$Body data-cy={DataCyStrings.nextStepsBodyReturnless}>{t("bodyReturnless")}</$Body>;
  }
  if (returnStatus === ReturnStatus.expired) {
    return <$Body data-cy={DataCyStrings.nextStepsBodyExpired}>{t("bodyExpired")}</$Body>;
  }
  if (deliveryDateObject) {
    return (
      <$Body data-cy={DataCyStrings.nextStepsBodyDelivered}>{t("bodyReturnIsBeingProcessed", { retailerName })}</$Body>
    );
  }
  if (departureDateObject) {
    return <$Body data-cy={DataCyStrings.nextStepsBodyDeparted}>{t("bodyReturnWillBeProcessed")}</$Body>;
  }
  if (returnStatus === ReturnStatus.started) {
    if (dropoffMethodID === DropoffMethodIDs.mail) {
      if (selectedLabelType === LabelType.printerlessLabel) {
        // show EU variant
        return <$Body data-cy={DataCyStrings.nextStepsBodyStartedQRCode}>{t("bodyQRCode")}</$Body>;
      }
      return <$Body data-cy={DataCyStrings.nextStepsBodyStartedShippingLabel}>{t("bodyShippingLabel")}</$Body>;
    } else if (dropoffMethodID === DropoffMethodIDs.mailShopperProvided) {
      return <$Body data-cy={DataCyStrings.nextStepsBodyStartedPackingSlip}>{t("bodyPackingSlip")}</$Body>;
    }
    // default to return bar / in-store copy
    return (
      <$Body>
        <ul>
          <li data-cy={DataCyStrings.nextStepsBodyStartedShippingMaterials}>{t("bodyShippingMaterials")}</li>
          <li data-cy={DataCyStrings.nextStepsBodyStartedShoes}>{t("bodyShoes")}</li>
          <li data-cy={DataCyStrings.nextStepsBodyStartedItemBarcodes}>{t("bodyItemBarcodes")}</li>
        </ul>
      </$Body>
    );
  }
  return <></>;
};

// TODO refactor, reduce code duplication
// TODO CF-994 button color state based on open draft order
// TODO CF-994 icon
const Button = ({
  deliveryDate,
  departureDate,
  dropoffMethodID,
  retailerID,
  returnID,
  returnlessRefund,
  returnStatus,
  selectedLabelType,
  shipmentTrackingURL,
  storeLocatorURL,
  zipCode,
  latitude,
  longitude
}: ReturnStatusComposite) => {
  const dispatch = useDispatch();
  const { t } = getTranslator("NextStepsCard")();
  const deliveryDateObject = getDate(deliveryDate);
  const departureDateObject = getDate(departureDate);

  if (returnlessRefund) {
    return <></>;
  }
  if (returnStatus === ReturnStatus.expired) {
    return (
      <PrimaryButton
        dataCyString={DataCyStrings.nextStepsButtonStartNewReturn}
        onButtonClick={() => {
          ga.event({
            category: AnalyticCategories.ReturnStatusPage,
            action: ReturnStartedAndReturnStatusPageActions.StartANewReturn,
          });
          window.history.replaceState(null, document.title, window.location.pathname);
          dispatch(reset());
          dispatch(setToken(""));
          dispatch(goToPage("loginReturnPortal"));
        }}
        width={"auto"}
      >
        <$ButtonContents>
          <div>{t("callToActionStartNewReturn")}</div>
        </$ButtonContents>
      </PrimaryButton>
    );
  }
  if (departureDateObject || deliveryDateObject) {
    if (shipmentTrackingURL) {
      // URLs must begin with https://, https://, or omit a leading www.
      try {
        const validatedShipmentTrackingURL = new URL(shipmentTrackingURL).href;
        return (
          <PrimaryButton
            dataCyString={DataCyStrings.nextStepsButtonTrackReturn}
            onButtonClick={() => {
              ga.event({
                category: AnalyticCategories.ReturnStatusPage,
                action: ReturnStartedAndReturnStatusPageActions.TrackReturn,
              });
              window.open(validatedShipmentTrackingURL, "_blank");
            }}
            width={"auto"}
          >
            <$ButtonContents>
              <div>{t("callToActionTrackReturn")}</div>
              <SVG name="external-link" />
            </$ButtonContents>
          </PrimaryButton>
        );
      } catch (error) {
        console.error(error); // TODO CF-994 Datadog logger
      }
    }
    return <></>;
  }
  if (returnStatus === ReturnStatus.started) {
    if (dropoffMethodID === DropoffMethodIDs.mail) {
      if (selectedLabelType === LabelType.printerlessLabel) {
        return <></>;
      }
      return (
        <PrimaryButton
          dataCyString={DataCyStrings.nextStepsButtonShippingLabel}
          onButtonClick={() => {
            ga.event({
              category: AnalyticCategories.ReturnStatusPage,
              action: ReturnStartedAndReturnStatusPageActions.DownloadShippingLabel,
            });
            window.open(`/shippinglabel/${returnID}`, "_blank");
          }}
          width={"auto"}
        >
          <$ButtonContents>
            <SVG name="printer" />
            <div>{t("callToActionShippingLabel")}</div>
          </$ButtonContents>
        </PrimaryButton>
      );
    }
    if (dropoffMethodID === DropoffMethodIDs.mailShopperProvided) {
      return (
        <PrimaryButton
          dataCyString={DataCyStrings.nextStepsButtonPackingSlip}
          onButtonClick={() => {
            ga.event({
              category: AnalyticCategories.ReturnStatusPage,
              action: ReturnStartedAndReturnStatusPageActions.DownloadPackingSlip,
            });
            window.open(`/packingslip/${returnID}`, "_blank");
          }}
          width={"auto"}
        >
          <$ButtonContents>
            <SVG name="printer" />
            <div>{t("callToActionPackingSlip")}</div>
          </$ButtonContents>
        </PrimaryButton>
      );
    }
    if (dropoffMethodID === DropoffMethodIDs.retailerStore && storeLocatorURL) {
      try {
        // URLs must begin with https://, https://, or omit a leading www.
        const validatedStoreLocatorURL = new URL(storeLocatorURL).href;
        return (
          <PrimaryButton
            dataCyString={DataCyStrings.nextStepsButtonFindLocation}
            onButtonClick={() => {
              ga.event({
                category: AnalyticCategories.ReturnStatusPage,
                action: ReturnStartedAndReturnStatusPageActions.SeeAllLocations,
              });
              window.open(validatedStoreLocatorURL, "_blank");
            }}
            width={"auto"}
          >
            <$ButtonContents>
              <div>{t("callToActionLocation")}</div>
              <SVG name="external-link" />
            </$ButtonContents>
          </PrimaryButton>
        );
      } catch (err) {
        console.error(err); // TODO CF-994 Datadog logger
      }
      return <></>;
    }
    if (dropoffMethodID === DropoffMethodIDs.returnBar) {
      return (
        <PrimaryButton
          dataCyString={DataCyStrings.nextStepsButtonFindReturnBar}
          onButtonClick={() => {
            ga.event({
              category: AnalyticCategories.ReturnStatusPage,
              action: ReturnStartedAndReturnStatusPageActions.SeeAllLocations,
            });
            let locationsURL = `https://locations.happyreturns.com?has_qr_code=true&retailer=${retailerID}&address=${zipCode}`
            if (latitude && longitude) {
              locationsURL += `&latitude=${latitude}&longitude=${longitude}`
            }
            window.open(
              locationsURL,
              "_blank"
            );
          }}
          width={"auto"}
        >
          <$ButtonContents>
            <div>{t("callToActionReturnBar")}</div>
            <SVG name="external-link" />
          </$ButtonContents>
        </PrimaryButton>
      );
    }
  }
  return <></>;
};

const Disclaimer = ({
  dropoffMethodID,
  locationBlockMilesThreshold,
  returnStatus
}: ReturnStatusComposite) => {
  const { t } = getTranslator("NextStepsCard")();

  if (returnStatus === ReturnStatus.started && dropoffMethodID === DropoffMethodIDs.returnBar) {
    return (
      <$DisclaimerLocationMilesThreshold
        data-cy={DataCyStrings.nextStepsBodyStartedLocationMilesThreshold}>
          {t("bodyLocationBlockMilesThreshold", {
            locationBlockMilesThreshold: locationBlockMilesThreshold,
          })}
      </$DisclaimerLocationMilesThreshold>
    );
  }

  return <></>;
};

const QRCard = ({
  departureDate,
  dropoffMethodID,
  expressCode,
  expiryDate,
  numItemsInReturn,
  retailerName,
  returnID,
  returnlessRefund,
  returnStatus,
  selectedCarrierName,
  selectedLabelType,
  isRetailerStoreApplePassEnabled,
}: ReturnStatusComposite) => {
  const dispatch = useDispatch();
  const expiryDateObject = getDate(expiryDate);

  const [QRCodeDataURL, setQRCodeDataURL] = useState("");
  const [showBleckmannQRCode, setShowBleckmannQRCode] = useState(false)

  useEffect(() => {
    if (
      dropoffMethodID !== DropoffMethodIDs.returnBar &&
      dropoffMethodID !== DropoffMethodIDs.retailerStore &&
      !expressCode
    ) {
      return;
    }

    // If there is carrier selection(bleckmann), don't show the default QR code
    if (!!selectedCarrierName){
      return;
    }

    const generateQRCodeDataURL = async (expressCode) => {
      try {
        const URL = await QRCode.toDataURL(expressCode);
        setQRCodeDataURL(URL);
      } catch (err) {
        console.error(err); // TODO CF-994 Datadog logger
      }
    };
    generateQRCodeDataURL(expressCode);
  }, [expressCode]);

  // Printerless (QR code) mail labels differ from our typical QR codes.
  // Instead of generating them on-the-fly, we store URLs to these mail labels, like our traditional printer labels.
  // In a departure from the previous `ReturnStarted` page, instead of embedding the URL directly in an img element,
  // we explicitly query the relevant endpoint and convert the response such that we can ultimately reuse the same
  // `QRCodeDataURL` state variable used for Express Code QR codes.
  // This explicit querying allows us to display a loading indicator, to more gracefully handle errors, etc.
  useEffect(() => {
    if (!returnID || !selectedCarrierName) {
      return;
    }

    if (dropoffMethodID !== DropoffMethodIDs.mail && selectedLabelType !== LabelType.printerlessLabel) {
      return;
    }

    // If the departure date exists, do not call the /qrcode api or show the bleckmann QR code
    if (departureDate) {
      return
    }

    const getQRCodeMailLabel = async (returnID: string) => {
      // TODO CF-1202 re-implement retry behavior
      // TODO optimization, avoid re-fetch when switching between desktop and mobile layouts
      try {
        dispatch(showLoadingIndicator(defaultLoadingSymbol));
        const response = await axios.get(`/qrcode/${returnID}`, { responseType: "arraybuffer" });
        const base64Response = Buffer.from(response.data, "binary").toString("base64");
        setQRCodeDataURL(`data:image;base64,${base64Response}`);
        setShowBleckmannQRCode(true)
      } catch (err) {
        console.error(err); // TODO CF-994 Datadog logger
        setShowBleckmannQRCode(false)
      } finally {
        dispatch(clearLoadingIndicator());
      }
    };
    getQRCodeMailLabel(returnID);
  }, [returnID]);

  if (returnlessRefund || returnStatus == ReturnStatus.expired) {
    return <></>;
  }
  if (dropoffMethodID === DropoffMethodIDs.returnBar && QRCodeDataURL) {
    return (
      <$QRCard data-cy={DataCyStrings.nextStepsQRCard}>
        <$HappyReturnsBanner>
          <$HappyReturnsLogo>
            <SVG name={"hr-logo-happyreturns"} />
          </$HappyReturnsLogo>
        </$HappyReturnsBanner>
        <img src={QRCodeDataURL} alt={expressCode} data-cy={DataCyStrings.nextStepsQRCodeImageExpressCode} />
        <$QRCardText data-cy={DataCyStrings.nextStepsQRCodeText}>{expressCode}</$QRCardText>
        {isAppleDevice() && expiryDateObject && expressCode && numItemsInReturn && retailerName && returnID && (
          <ExpressCodeApplePassButton
            confirmationCode={expressCode}
            expirationDate={expiryDateObject}
            numItemsInReturn={numItemsInReturn}
            retailerName={retailerName}
            returnID={returnID}
            // We use changeDropoffMethodReturnID instead of returnID to use the unauthenticated endpoint
            changeDropoffMethodReturnID={returnID}
          />
        )}
      </$QRCard>
    );
  }
  if (dropoffMethodID === DropoffMethodIDs.retailerStore && QRCodeDataURL) {
    return (
      <$QRCard data-cy={DataCyStrings.nextStepsQRCard}>
        <img src={QRCodeDataURL} alt={expressCode} data-cy={DataCyStrings.nextStepsQRCodeImageExpressCode} />
        <$QRCardText data-cy={DataCyStrings.nextStepsQRCodeText}>{expressCode}</$QRCardText>
        {isAppleDevice() && isRetailerStoreApplePassEnabled && expiryDateObject && expressCode && numItemsInReturn && retailerName && returnID && (
        <ExpressCodeApplePassButton
          confirmationCode={expressCode}
          expirationDate={expiryDateObject}
          numItemsInReturn={numItemsInReturn}
          retailerName={retailerName}
          returnID={returnID}
          // We use changeDropoffMethodReturnID instead of returnID to use the unauthenticated endpoint
          changeDropoffMethodReturnID={returnID}
        />
        )}
      </$QRCard>
    );
  }
  if (dropoffMethodID === DropoffMethodIDs.mail && selectedLabelType === LabelType.printerlessLabel && showBleckmannQRCode) {
    return (
      <$QRCard data-cy={DataCyStrings.nextStepsQRCard}>
        <$QRCode src={QRCodeDataURL} alt={""} data-cy={DataCyStrings.nextStepsQRCodeImageMailLabel} />
        <$QRCardText data-cy={DataCyStrings.nextStepsQRCarrierText}>{selectedCarrierName}</$QRCardText>
      </$QRCard>
    );
  }
  return <></>;
};

const ConfirmationDetails = ({
  deliveryDate,
  departureDate,
  emailAddress,
  otherDropoffMethodsAvailable,
  returnlessRefund,
  returnID,
  returnStatus,
}: ReturnStatusComposite) => {
  const { t } = getTranslator("NextStepsCard")();
  const { locale } = useSelector((store) => store.app);
  const deliveryDateObject = getDate(deliveryDate);
  const departureDateObject = getDate(departureDate);

  const [emailModalOpen, setEmailModalOpen] = useState(false);

  if (!emailAddress) {
    return <></>;
  }
  if (returnStatus === ReturnStatus.expired) {
    return <></>;
  }
  if (deliveryDateObject || departureDateObject) {
    return <></>;
  }
  return (
    <$ConfirmationDetails>
      <$ConfirmationDetailsHeader data-cy={DataCyStrings.nextStepsConfirmationDetailsHeader}>
        {t("confirmationDetailsHeader", { emailAddress })}
      </$ConfirmationDetailsHeader>
      <$ConfirmationDetailsLink>
        <SVG name={"envelope-check"} />
        <a
          {...handleEnterKeyPressOnClickHandlers(() => {
            ga.event({
              category: AnalyticCategories.ReturnStatusPage,
              action: ReturnStartedAndReturnStatusPageActions.SendToAnotherEmail,
            });
            setEmailModalOpen(true);
          })}
          data-cy={DataCyStrings.nextStepsConfirmationDetailsResendEmailLink}
        >
          {t("confirmationDetailsSendToAnotherEmail")}
        </a>
      </$ConfirmationDetailsLink>
      <ResendEmailModal isOpen={emailModalOpen} onClose={() => setEmailModalOpen(false)} returnID={returnID} />
    </$ConfirmationDetails>
  );
};

const ResendEmailModal = ({
  isOpen,
  onClose,
  returnID,
}: {
  isOpen: boolean;
  onClose: () => void;
  returnID: string | undefined;
}) => {
  const { t } = getTranslator("NextStepsCard")();
  const dispatch = useDispatch();

  if (!returnID) {
    return <></>;
  }

  return (
    <EmailModal
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={() => {}} // TODO Fix warning
      onSubmit={async ({ email, setError, onSuccess }) => {
        dispatch(showLoadingIndicator(defaultLoadingSymbol));
        try {
          await axios.post(`return/${returnID}/email/confirmation`, { email });
          onSuccess(email);
        } catch (err) {
          setError(t("emailModalError"));
        } finally {
          dispatch(clearLoadingIndicator());
        }
      }}
      primaryMessage={t("emailModalHeader")}
      subMessage={t("emailModalSubheader")}
      successMessage={t("emailModalSuccess")}
    />
  );
};

const isButtonFocused = ({ returnShoppingItems, isDraftOrderPaid }: ReturnStatusComposite): boolean => {
  if (returnShoppingItems) {
    return !!isDraftOrderPaid;
  }
  return true;
};

const renderDesktopReturnStatus = (returnStatusComposite: ReturnStatusComposite) => {
  const { app } = useSelector(store => store);
  const colors = app?.colors

  return (
    <$NextStepsCard isButtonFocused={isButtonFocused(returnStatusComposite)} primaryColor={colors?.primaryColor}>
      <$PrimaryDetails>
        <$PrimaryDetailsLeftColumn>
          <Header {...returnStatusComposite} />
          <Body {...returnStatusComposite} />
          <Button {...returnStatusComposite} />
          <Disclaimer {...returnStatusComposite}/>
        </$PrimaryDetailsLeftColumn>
        <QRCard {...returnStatusComposite} />
      </$PrimaryDetails>
      <ConfirmationDetails {...returnStatusComposite} />
    </$NextStepsCard>
  );
};

const renderMobileNextStepsCard = (returnStatusComposite: ReturnStatusComposite) => {
  const { app } = useSelector(store => store);
  const colors = app?.colors

  return (
    <$NextStepsCard isButtonFocused={isButtonFocused(returnStatusComposite)} primaryColor={colors?.primaryColor}>
      <$PrimaryDetails>
        <$PrimaryDetailsSingleColumn>
          <Header {...returnStatusComposite} />
          <Body {...returnStatusComposite} />
          <QRCard {...returnStatusComposite} />
          <Button {...returnStatusComposite} />
          <Disclaimer {...returnStatusComposite}/>
        </$PrimaryDetailsSingleColumn>
      </$PrimaryDetails>
      <ConfirmationDetails {...returnStatusComposite} />
    </$NextStepsCard>
  );
};

// TODO CF-994 QR card (EU QR code), confirmation details link functionality
export const NextStepsCard = (returnStatusComposite: ReturnStatusComposite) => {
  const { width } = useViewport();
  if (width < MOBILE_BREAKPOINT) {
    return renderMobileNextStepsCard(returnStatusComposite);
  }
  return renderDesktopReturnStatus(returnStatusComposite);
};


import { AppRuntimes } from "../../types/AppRuntimes"
import { Instance } from "../../types/Instance"
import { PurchaseReturnability } from "../../types/Instance"

// given the context, returns the itemIsReturnable(instance) : boolean func
export const getItemIsReturnableFn = ({
    isAdminMode,
    isRetailer,
    runtime,
    setIsOverrideModalOpen,
    setFulfillOverride
  }) => 
    async (instance: Instance) : Promise<{returnable: boolean, overridden?: boolean}> => {

    // admin mode always allows returns
    // no override required
    if (isAdminMode)
      return {returnable: true}

    // item is returnable
    if (instance?.purchase?.returnable)
      return {returnable: true}
  
    // if instance has 0 returns available
    // this is a temporary hack until the morph returnability priorities are fixed
    if (instance?.purchaseID?.endsWith("-0"))
      return {returnable: false}
  
    // if instance was already overridden, return false
    if (instance?.overridden)
      return {returnable: false}
  
    //overrides only available for Returnista
    if (runtime != AppRuntimes.returnista)
      return {returnable: false}

    //overrides are only allowed for in-store retailers
    if (!isRetailer) 
      return {returnable: false}
  
    // get reason why item is unreturnable
    const returnability = instance?.purchase?.returnability
    if (!returnability)
      return {returnable: false}
      
    // allow override only:
    // - if item has shipped 
    // - unreturnable reason is one of select values
    // this logic is necessary because:
    // - the instance model only has 1 returnability reason
    // - FinalSale is used instead of NotYetShipped if both apply
    const isShipped = !!instance?.purchase?.fulfillmentDate
    if (!isShipped)
      return {returnable: false}
  
    const cannotBeOverridden = [PurchaseReturnability.NotYetShipped, PurchaseReturnability.AlreadyReturned, PurchaseReturnability.ReturnabilityPendingDeliveryStatusUpdate]
      .includes(returnability as PurchaseReturnability)
  
    if (cannotBeOverridden)
      return {returnable: false}
    
  
    //show modal
    setIsOverrideModalOpen(true)
  
    // create promise that resolves when modal is closed
    const modalClosePromise = new Promise(res => setFulfillOverride(() => res))
  
    // wait for modal to be closed to get override result
    const overridden = await modalClosePromise
  
    // a returnable override
    if (overridden){
      return {returnable: true, overridden: true}
    }
  
    // override failed, not returnable
    return {returnable: false}
  }
// third-party imports
import React, { useRef, useState } from "react";
import axios, { AxiosResponse } from "axios";
import produce from "immer";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

// repo imports
import { PageLifecycle } from "../";
import { LoginOfferings, PageProps, ReturnPortalLoginConfiguration } from "../types";
import { RootReducer } from "../../redux/reducers";
import useSelector from "../../utility/useTypedSelector";

// local imports
import $LoginReturnPortal from "./styles";
import ReturnPortalBase from "../../components/ReturnPortalBase";
import { LoginBase } from "../LogIn";
import { statsigClient } from "../../utility/useStatsigClient";
import { useEffect, useLayoutEffect } from "react";
import PrimaryButton from "../../components/Button/PrimaryButton";
import LanguagePicker from "../../components/LanguagePicker";

import TextInput from "../../components/TextInput";

import {
  clearLoadingIndicator,
  setLocalErrorMessage,
  setReturnShoppingEnabled,
  setToken,
  showLoadingIndicator,
} from "../../redux/app/actions";
import {
  handleEnterKeyPressOnClickHandlers,
  isValidEmail,
  mapURLQueryStringToObject,
  getJWTClaims,
  getAdminMode, getReturnistaJWTClaims,
} from "../../utility";
import { ErrorAlertMessage, NeutralMessage, SuccessMessage } from "../../components/AlertMessage";
import { setGiftReturnEmail } from "../../redux/customer/actions";
import { AnalyticCategories, AnalyticsPageRoutes, CommonPageActions, LoginPageActions } from "../../types/Analytics";
import getTranslator from "../../utility/getTranslator";
import { Trans } from "react-i18next";
import { defaultLoadingSymbol } from "../../components/LoadingIndicator";
import i18n from "../../i18n";
import ga from "../../utility/GAEmitter";
import { DataCyStrings } from "../../types/DataCyStrings";
import { PrivacyFooter } from "../../components/PrivacyFooter";

const useTranslation = getTranslator("LoginReturnPortal");
interface LoginFormProps {
  inputDisabledState: boolean;
  loginState: LoginState;
  allLoginOfferings: Array<LoginOfferings>;
  defaultLoginOffering: LoginOfferings;
  canSubmit: boolean;
  orderNumberMessage?: string;
  setLoginMethod: React.Dispatch<LoginOfferings>;
  onLoginStateChange: (key: string, val: string) => void;
  onSubmit: (e: any) => void;
}

export enum LoginKeys {
  orderNumber = "order_number",
  zipCode = "zip_code",
  giftZipCode = "gift_zip_code",
  email = "email",
  emailToken = "token",
  adminToken = "adminToken",
  giftEmail = "giftEmail",
  recaptchaValue = "recaptcha_value",
  locale = "locale",
}

const emailTokenQueryKey = "email_token";
const adminTokenQueryKey = "admin_token";

type LoginState = {
  [key in LoginKeys]?: string;
};

type loginOnError = (e: { response: AxiosResponse<{}> }) => void;

const defaultLoginStates = {
  [LoginOfferings.orderNumberZip]: {
    [LoginKeys.orderNumber]: "",
    [LoginKeys.zipCode]: "",
  },
  [LoginOfferings.email]: {
    [LoginKeys.email]: "",
  },
  [LoginOfferings.orderNumberEmail]: {
    [LoginKeys.orderNumber]: "",
    [LoginKeys.email]: "",
  },
  [LoginOfferings.giftReturn]: {
    [LoginKeys.orderNumber]: "",
    [LoginKeys.zipCode]: "",
    [LoginKeys.email]: "",
    isGift: true,
  },
};
class LoginReturnPortalLifecycle extends PageLifecycle {
  constructor(page, app, dispatch) {
    super(page, app, dispatch);
  }
}

/**
 * Utility method to call add locale to the loginState and
 * trim() on all the loginState values that are strings (i.e. have a trim method).
 */
const prepareState = (body: LoginState): LoginState => {
  const newState = { locale: i18n.language };
  Object.keys(body).map((key) => {
    if (body[key].trim) newState[key] = body[key].trim();
    else newState[key] = body[key];
  });
  return newState;
};

/**
 * login page for return portal
 */
const LoginReturnPortal = ({ page }: PageProps<ReturnPortalLoginConfiguration>) => {
  const { t, i18n } = useTranslation();
  const errorCopies = {
    400: t("weDontHaveARecord"),
    401: t("invalidReCaptchaValue"),
    403: t("weDontHaveARecord"),
    404: t("weDontHaveARecord"),
    451: t("weDontYetSupport"),
    500: t("wereHavingIssues"),
    invalidEmail: t("pleaseDoubleCheck"),
    noLanguageFile: t("noLanguageFile", "Unable to load selected language"),
  };

  // Error code for passing custom retailer's error message to the user
  const customLoginErrorCode = 455;
  //----------------------------------------------------------------------------
  // STATE
  const dispatch = useDispatch();
  const {
    app,
    app: { enableLocalization, locale, copies },
  } = useSelector((store: RootReducer) => store);
  const { localErrorMessage } = app;
  const lifecycle = new LoginReturnPortalLifecycle(page, dispatch, app);
  const loginOfferings = page?.pageSettings?.loginOfferings || {
    allLoginOfferings: [],
    default: LoginOfferings.orderNumberZip,
  };
  const retailerName = copies?.retailerName
  const [loginState, setLoginState] = useState<LoginState>({});

  const [loginMethod, setLoginMethod] = useState<LoginOfferings>(page.pageSettings.loginOfferings.default);

  const [isAdminMode, setIsAdminMode] = useState<boolean>(getAdminMode());

  // For disabling input fields on submitLogin
  const [inputDisabledState, setInputDisabledState] = useState<boolean>(false);

  // banner messages
  const [error, setError] = useState("");

  const [success, setSuccess] = useState("");

  const [info, setInfo] = useState("");

  // Captcha control
  const [captchaIsProcessing, setCaptchaIsProcessing] = useState<boolean>(false);
  const [captchaIntervalID, setCaptchaIntervalID] = useState<ReturnType<typeof setTimeout>>();
  const [captchaModalOnceOpened, setCaptchaModalOnceOpened] = useState<boolean>(false);

  // ---------------

  const canSubmit = Object.keys(loginState).reduce((canSubmit, currKey) => {
    if (canSubmit) {
      if (currKey == "isGift") return true;
      return typeof loginState[currKey] === "string" && loginState[currKey].trim() != "";
    } else {
      return false;
    }
  }, true);

  // placeholder function replaced by recaptcha in runtime
  const recaptchaRef = useRef<{ reset: Function; executeAsync: Function }>({
    reset: () => { },
    executeAsync: () => { },
  });

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  // if the page mounts with queries, attempt to build a login using url query params
  useEffect(() => {
    const queries = mapURLQueryStringToObject();
    const requestBody: LoginState = {};
    const hasOrderZipOffering = loginOfferings.allLoginOfferings.includes(LoginOfferings.orderNumberZip);
    const hasOrderEmailOffering = loginOfferings.allLoginOfferings.includes(LoginOfferings.orderNumberEmail);

    // Support `zipcode` for backwards compatibility with ORES-style deeplinking
    if (queries["zipcode"]) {
      queries[LoginKeys.zipCode] = queries["zipcode"];
    }

    if (queries[LoginKeys.orderNumber] && queries[LoginKeys.zipCode] && hasOrderZipOffering) {
      requestBody[LoginKeys.orderNumber] = queries[LoginKeys.orderNumber];
      requestBody[LoginKeys.zipCode] = queries[LoginKeys.zipCode];
      loginState[LoginKeys.orderNumber] = queries[LoginKeys.orderNumber];
      loginState[LoginKeys.zipCode] = queries[LoginKeys.zipCode];
      setLoginState({ ...loginState});
      submitLogin(requestBody, handleLoginError);
    } else if (queries[LoginKeys.orderNumber] && queries[LoginKeys.email] && hasOrderEmailOffering) {
      requestBody[LoginKeys.orderNumber] = queries[LoginKeys.orderNumber];
      requestBody[LoginKeys.email] = queries[LoginKeys.email];
      loginState[LoginKeys.orderNumber] = queries[LoginKeys.orderNumber];
      loginState[LoginKeys.email] = queries[LoginKeys.email];
      setLoginState({ ...loginState});
      submitLogin(requestBody, handleLoginError);
    } else if (queries[emailTokenQueryKey]) {
      requestBody[LoginKeys.emailToken] = queries[emailTokenQueryKey];
      loginState[LoginKeys.emailToken] = queries[emailTokenQueryKey];
      setLoginState({ ...loginState});
      submitLogin(requestBody, handleLoginError);
    }
  }, []);

  useLayoutEffect(() => {
    // flush user input when login method changes
    setLoginState({ ...defaultLoginStates[loginMethod] });
  }, [loginMethod]);

  // clear banners (error / success / info) when
  // input or method change
  useLayoutEffect(() => {
    reinitBannerStates();
  }, [loginState, loginMethod, locale]);

  useEffect(() => {
    const queries = mapURLQueryStringToObject();
    if (!queries[adminTokenQueryKey]) {
      return;
    }

    const adminTokenClaims = getJWTClaims(queries[adminTokenQueryKey]);

    // Date.now() returns a unix timestamp with ms - the claims exp is unic timestamp without ms
    const now = Math.floor(Date.now() / 1000);

    if (adminTokenClaims !== undefined && now > adminTokenClaims.exp) {
      alert(t("yourAdminAccessHas"));
    }
  }, []);

  useEffect(() => {
    if (isAdminMode) {
      dispatch(setReturnShoppingEnabled(false));
    }
  }, [isAdminMode]);

  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale,
        change_dropoff: false
      }
    });
    ga.sendPageDetails(AnalyticsPageRoutes.Login, AnalyticCategories.LoginPage);
  }, []);

  useEffect(() => {
    if (captchaIntervalID && captchaModalOnceOpened) {
      clearInterval(captchaIntervalID);
    }
    if (captchaIsProcessing) {
      const intervalID = setInterval(() => {handleRecaptchaOverlay();}, 1000)
      setCaptchaIntervalID(intervalID);
    }
  }, [captchaIsProcessing, captchaModalOnceOpened]);

//----------------------------------------------------------------------------

//----------------------------------------------------------------------------
// HELPERS
  const handleRecaptchaOverlay = () => {
    if (!captchaIsProcessing) return;

    const recaptchaIframes = document.querySelectorAll('iframe[src*="recaptcha/api2/bframe"]');
    if (recaptchaIframes.length === 0) return;

    const recaptchaOverlay = (recaptchaIframes[0]?.parentNode?.parentNode as HTMLElement);
    if (recaptchaOverlay && captchaIsProcessing) {
      let isRecaptchaOverleyVisible = recaptchaOverlay?.style?.visibility === 'hidden' ? false : true;
      if (!captchaModalOnceOpened && isRecaptchaOverleyVisible) {
        setCaptchaModalOnceOpened(true);
      } else if (captchaModalOnceOpened && !isRecaptchaOverleyVisible) {
        recaptchaRef?.current?.reset();
        dispatch(clearLoadingIndicator());
        setInputDisabledState(false);
        setCaptchaIsProcessing(false);
      }
    }
    return;
  };


  const saveTokenAndAdvance = (token: string) => {
    dispatch(setToken(token));
    const claims = getReturnistaJWTClaims(token)
    if (claims?.returnsApp?.analyticsToken) {
      ga.setAnalyticsToken(claims?.returnsApp?.analyticsToken)
    }

    statsigClient.initialize({
      email: claims?.Identity?.email,
      retailerID: claims?.returnsApp?.retailerID,
      environment: window?.appConfig?.HR_ENVIRONMENT ?? "local",
      locale: app.locale
    });

    lifecycle.advance();
  };

  const submitLogin = async (body: LoginState, onError?: loginOnError, onSuccess = saveTokenAndAdvance) => {
    const req = prepareState(body);
    dispatch(setLocalErrorMessage(""));
    dispatch(showLoadingIndicator(defaultLoadingSymbol));
    try {
      setInputDisabledState(true);

      // If admin mode, include admin token to enable pass-thru authentication
      const queries = mapURLQueryStringToObject();
      if (queries[adminTokenQueryKey]) {
        req[LoginKeys.adminToken] = queries[adminTokenQueryKey];
      }

      //if admin token is missing and recaptcha token needs to be displayed
      if (app.recaptchaToken && !req[LoginKeys.adminToken]) {
        setCaptchaIsProcessing(true);
        req[LoginKeys.recaptchaValue] = await recaptchaRef?.current?.executeAsync?.();
        setCaptchaIsProcessing(false);
        if (req.email) {
          recaptchaRef?.current?.reset();
        }
      }
      const resp = await axios.post<{ token: string }>("/login", req);
      if (req["isGift"]) {
        dispatch(setGiftReturnEmail(req.email));
        dispatch(setReturnShoppingEnabled(false));
      }
      onSuccess(resp.data.token);
    } catch (e) {
      if (e?.response) {
        onError && onError(e as { response: AxiosResponse });
      }
    } finally {
      setInputDisabledState(false);
    }
    dispatch(clearLoadingIndicator());
  };

  const reinitBannerStates = () => {
    setInfo(loginMethod === LoginOfferings[LoginOfferings.email] ? t("forYourProtection") : "");
    setSuccess("");
    setError("");
  };

  const handleLoginError = (resp) => {
    // When ReCAPTCHA is enabled and login fails, force the component to reset.
    if (app.recaptchaToken) {
      recaptchaRef?.current?.reset();
    }
    if (resp?.response?.status && errorCopies[resp.response.status]) {
      setError(errorCopies[resp.response.status]);
    } else if (resp?.response?.status == customLoginErrorCode) {
      setError(resp?.response?.data);
    }
  };

  //----------------------------------------------------------------------------

  //---------------------------------------------------------------------------
  // HANDLERS
  const onLoginStateChange = (key, val) => {
    setLoginState(
      produce(loginState, (draft) => {
        draft[key] = val;
        return draft;
      })
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loginState && loginState.order_number && loginState.email) {
      ga.event({
        category: AnalyticCategories.LoginPage,
        action: LoginPageActions.StartYourReturnFromOrderZipPage
      })
    } else if (loginState && loginState.order_number && loginState.zip_code) {
      ga.event({
        category: AnalyticCategories.LoginPage,
        action: LoginPageActions.StartYourReturnFromOrderZipPage
      })
    }
    submitLogin(loginState, handleLoginError);
  };

  const onSubmitEmail = (e) => {
    e.preventDefault();
    // if an email is defined and is invalid
    if (loginState.email && !isValidEmail(loginState.email)) {
      setError(errorCopies.invalidEmail);
      return;
    }
    if (loginState && loginState.order_number && loginState.email) {
      ga.event({
        category: AnalyticCategories.LoginPage,
        action: LoginPageActions.StartYourReturnFromOrderEmailPage
      })
    } else {
      ga.event({
        category: AnalyticCategories.LoginPage,
        action: LoginPageActions.EmailLinkStartYourReturnFromEmailPage
      })
    }
    // if a token is returned (for admin mode) save token and advance
    // otherwise show success message for normal email login
    submitLogin(loginState, handleLoginError, (token) => {
      if (token) {
        saveTokenAndAdvance(token);
      } else {
        setSuccess(t("aLinkHasBeenSentTo"));
      }
    });
  };

  const onSubmitGift = (e) => {
    e.preventDefault();
    // if an email is defined and is invalid
    if (loginState.email && !isValidEmail(loginState.email)) {
      setError(errorCopies.invalidEmail);
      return;
    }
    ga.event({
      category: AnalyticCategories.LoginPage,
      action: LoginPageActions.StartYourGiftReturnFromGiftReturnPage
    })
    submitLogin(loginState, handleLoginError);
  };

  const onPrivacyPolicyClick = () => {
    ga.event({
      category: AnalyticCategories.LoginPage,
      action: CommonPageActions.PrivacyPolicy
    })
  };

  const loginFunctionsByOffering = {
    [LoginOfferings.email]: onSubmitEmail,
    [LoginOfferings.orderNumberEmail]: onSubmitEmail,
    [LoginOfferings.giftReturn]: onSubmitGift,
  };
  //---------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDERING
  const loginForms = {
    [LoginOfferings.orderNumberZip]: OrderZipForm,
    [LoginOfferings.orderNumberEmail]: OrderEmailForm,
    [LoginOfferings.giftReturn]: GiftForm,
    [LoginOfferings.email]: EmailForm,
  };
  // get login form component based on selected offering
  const LoginForm = loginForms[loginMethod];
  // in case a login offering exhibits alternate behavior
  const loginSubmitFn = loginFunctionsByOffering[loginMethod] ? loginFunctionsByOffering[loginMethod] : onSubmit;

  return (
    <ReturnPortalBase>
      <$LoginReturnPortal>
      {/* In order to preserve the whole styles it was necessary to create this div. */}
      <div/>
      <div className="hide-on-large-browser-width mobile-background"></div>
        <LoginBase
          logoURL={app?.links?.logo}
          homeURL={app?.links?.homeURL}
          retailerName={app?.copies?.retailerName}
          header={app?.copies?.retailerMessage}
          loginError=""
          isAdminMode={isAdminMode}
        >
          <>
            <Banner info={info} localErrorMessage={localErrorMessage} error={error} success={success} />
            <LoginForm
              inputDisabledState={inputDisabledState}
              loginState={loginState}
              allLoginOfferings={loginOfferings.allLoginOfferings}
              defaultLoginOffering={loginOfferings.default}
              orderNumberMessage={app?.copies?.orderNumberMessage}
              canSubmit={canSubmit}
              setLoginMethod={setLoginMethod}
              onLoginStateChange={onLoginStateChange}
              onSubmit={loginSubmitFn}
            />
            <div className="privacy-policy" data-cy={DataCyStrings.privacyPolicy}>
              <Trans t={t} i18nKey={"byContinuingYouAgree"}>
                By continuing, you agree to our <a target="_blank" onClick={onPrivacyPolicyClick} href={`https://privacypolicy.happyreturns.com/${app?.locale?.toLowerCase() || ''}`} rel="noreferrer">Privacy Policy</a>.
                We may use location services, including Google, to provide services to you.
              </Trans>
            </div>
            {enableLocalization && <div className="login-splitter" />}
            {enableLocalization && <LanguagePicker handleLanguageError={() => setError(errorCopies.noLanguageFile)} />}
          </>
        </LoginBase>
        {app.recaptchaToken && <div className="g-recaptcha"><ReCAPTCHA ref={recaptchaRef} badge="bottomright" size="invisible" sitekey={app.recaptchaToken} /></div> }
        <PrivacyFooter />
      </$LoginReturnPortal>
    </ReturnPortalBase>
  );
  //----------------------------------------------------------------------------
};

const OrderZipForm = ({
  inputDisabledState,
  loginState,
  canSubmit,
  allLoginOfferings,
  setLoginMethod,
  onLoginStateChange,
  onSubmit,
  orderNumberMessage,
}: LoginFormProps) => {
  const { t } = useTranslation();
  const hasEmailOffering = allLoginOfferings.includes(LoginOfferings.email);
  const hasGiftOffering = allLoginOfferings.includes(LoginOfferings.giftReturn);

  const emailOfferingHandlers = handleEnterKeyPressOnClickHandlers(() => {
    ga.event({
      category: AnalyticCategories.LoginPage,
      action: LoginPageActions.StartWithEmailClickFromOrderZipPage
    })
    setLoginMethod(LoginOfferings.email)
  });
  const giftOfferingHandlers = handleEnterKeyPressOnClickHandlers(() => {
    ga.event({
      category: AnalyticCategories.LoginPage,
      action: LoginPageActions.StartHereForGiftFromOrderZipPage
    })
    setLoginMethod(LoginOfferings.giftReturn)
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextInput
          label={t("orderNumberId")}
          value={loginState[LoginKeys.orderNumber] || ""}
          onChange={(val) => onLoginStateChange(LoginKeys.orderNumber, val)}
          forceTopScrollOnBlur
          dataCyString={DataCyStrings.orderNumberInput}
          disabledState={inputDisabledState}
        />
        {orderNumberMessage && <div className="order-number-message" data-cy={DataCyStrings.orderNumberMessage}>{orderNumberMessage}</div>}
        <TextInput
          label={t("zipPostalCode")}
          value={loginState[LoginKeys.zipCode] || ""}
          onChange={(val) => onLoginStateChange(LoginKeys.zipCode, val)}
          forceTopScrollOnBlur
          dataCyString={DataCyStrings.zipCodeInput}
          disabledState={inputDisabledState}
        />
        <div className={hasEmailOffering && hasGiftOffering ? "two-login-offerings" : "one-login-offering"}>
          {hasEmailOffering && (
            <div
              className="change-login-offering"
              data-cy={DataCyStrings.startWithAnEmailButton}
              {...emailOfferingHandlers}
            >
              {t("startWithAnEmail")}
            </div>
          )}
          {hasGiftOffering && (
            <div data-cy={DataCyStrings.receivedAGift}>
              <Trans t={t} i18nKey="receivedAGift">
                Received a gift?
                <div
                  className="change-login-offering"
                  data-cy={DataCyStrings.giftReturnButton}
                  {...giftOfferingHandlers}
                >
                  Start Here
                </div>
              </Trans>
            </div>
          )}
        </div>
        <PrimaryButton
          label={t("startYourReturn")}
          width="100%"
          disabled={!canSubmit}
          dataCyString={DataCyStrings.startYourReturnButton}
        />
      </form>
    </>
  );
};

const GiftForm = ({
  inputDisabledState,
  loginState,
  canSubmit,
  allLoginOfferings,
  orderNumberMessage,
  setLoginMethod,
  onLoginStateChange,
  onSubmit,
}: LoginFormProps) => {
  const { t } = useTranslation();
  const hasOrderZip = allLoginOfferings.includes(LoginOfferings.orderNumberZip);
  const hasOrderEmail = allLoginOfferings.includes(LoginOfferings.orderNumberEmail);
  const hasEmail = allLoginOfferings.includes(LoginOfferings.email);
  // used when the user decides to switch out of a gift return
  // if neither offerings are offered,
  let loginOffering: LoginOfferings = LoginOfferings.giftReturn;

  // attempt to find a return method to allow the user
  // to navigate away from the gift login form
  if (hasOrderZip) {
    loginOffering = LoginOfferings.orderNumberZip;
  } else if (hasOrderEmail) {
    loginOffering = LoginOfferings.orderNumberEmail;
  } else if (hasEmail) {
    loginOffering = LoginOfferings.email;
  }

  const loginOfferingHandlers = handleEnterKeyPressOnClickHandlers(() => {
    ga.event({
      category: AnalyticCategories.LoginPage,
      action: LoginPageActions.StartHereForNotAGiftFromGiftReturnPage
    })
    setLoginMethod(loginOffering)
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextInput
          label={t("orderNumberId")}
          value={loginState[LoginKeys.orderNumber] || ""}
          onChange={(val) => onLoginStateChange(LoginKeys.orderNumber, val)}
          forceTopScrollOnBlur
          dataCyString={DataCyStrings.orderNumberInput}
          disabledState={inputDisabledState}
        />
        {orderNumberMessage && <div className="order-number-message">{orderNumberMessage}</div>}
        <TextInput
          label={t("shippingZipPostal")}
          value={loginState[LoginKeys.zipCode] || ""}
          onChange={(val) => onLoginStateChange(LoginKeys.zipCode, val)}
          forceTopScrollOnBlur
          dataCyString={DataCyStrings.zipCodeInput}
          disabledState={inputDisabledState}
        />
        <TextInput
          label={t("giftReceiversEmail")}
          value={loginState[LoginKeys.email] || ""}
          onChange={(val) => onLoginStateChange(LoginKeys.email, val)}
          forceTopScrollOnBlur
          dataCyString={DataCyStrings.emailAddressInput}
          disabledState={inputDisabledState}
        />
        <div className="one-login-offering">
          {loginOffering != LoginOfferings.giftReturn && (
            <div>
              <Trans t={t} i18nKey="notAGift">
                Not a gift?
                <div
                  className="change-login-offering"
                  data-cy={DataCyStrings.notAGiftButton}
                  {...loginOfferingHandlers}
                >
                  Start Here
                </div>
              </Trans>
            </div>
          )}
        </div>
        <PrimaryButton label={t("startYourGiftReturn")} disabled={!canSubmit} />
      </form>
    </>
  );
};

const OrderEmailForm = ({
  inputDisabledState,
  loginState,
  canSubmit,
  allLoginOfferings,
  setLoginMethod,
  onLoginStateChange,
  onSubmit,
  orderNumberMessage,
}: LoginFormProps) => {
  const hasEmailOffering = allLoginOfferings.includes(LoginOfferings.email);
  const hasGiftOffering = allLoginOfferings.includes(LoginOfferings.giftReturn);

  const emailOfferingHandlers = handleEnterKeyPressOnClickHandlers(() => {
    setLoginMethod(LoginOfferings.email)
  });
  const giftOfferingHandlers = handleEnterKeyPressOnClickHandlers(() => setLoginMethod(LoginOfferings.giftReturn));
  const { t } = useTranslation();

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextInput
          label={t("orderNumberId")}
          value={loginState[LoginKeys.orderNumber] || ""}
          onChange={(val) => onLoginStateChange(LoginKeys.orderNumber, val)}
          forceTopScrollOnBlur
          dataCyString={DataCyStrings.orderNumberInput}
          disabledState={inputDisabledState}
        />
        {orderNumberMessage && <div className="order-number-message">{orderNumberMessage}</div>}
        <TextInput
          label={t("emailAddress")}
          value={loginState[LoginKeys.email] || ""}
          onChange={(val) => onLoginStateChange(LoginKeys.email, val)}
          forceTopScrollOnBlur
          dataCyString={DataCyStrings.emailAddressInput}
          disabledState={inputDisabledState}
        />
        <div className={hasEmailOffering && hasGiftOffering ? "two-login-offerings" : "one-login-offering"}>
          {hasEmailOffering && (
            <div
              className="change-login-offering"
              data-cy={DataCyStrings.startWithAnEmailButton}
              {...emailOfferingHandlers}
            >
              {t("startWithAnEmail")}
            </div>
          )}
          {hasGiftOffering && (
            <div>
              <Trans t={t} i18nKey="receivedAGift">
                Received a gift?
                <div
                  className="change-login-offering"
                  data-cy={DataCyStrings.giftReturnButton}
                  {...giftOfferingHandlers}
                >
                  Start Here
                </div>
              </Trans>
            </div>
          )}
        </div>
        <PrimaryButton
          label={t("startYourReturn")}
          disabled={!canSubmit}
          dataCyString={DataCyStrings.startYourReturnButton}
        />
      </form>
    </>
  );
};

const EmailForm = ({
  inputDisabledState,
  loginState,
  allLoginOfferings,
  canSubmit,
  defaultLoginOffering,
  setLoginMethod,
  onLoginStateChange,
  onSubmit,
}: LoginFormProps) => {
  const hasGiftOffering = allLoginOfferings.includes(LoginOfferings.giftReturn);
  const hasOrderZip = allLoginOfferings.includes(LoginOfferings.orderNumberZip);
  const hasOrderEmail = allLoginOfferings.includes(LoginOfferings.orderNumberEmail);
  const { t } = useTranslation();

  // used when the user decides to switch out of an email return
  // loginOffering is initialized as the selected offering to
  // conditionally render the offering link if it's successfully
  // overridden by order/zip or order/email
  let loginOffering = LoginOfferings.email;

  // attempt to find a return method to allow the user
  // to navigate away from the gift login form
  if (hasOrderZip) {
    loginOffering = LoginOfferings.orderNumberZip;
  } else if (hasOrderEmail) {
    loginOffering = LoginOfferings.orderNumberEmail;
  }

  const offeringsClassName =
    hasGiftOffering && loginOffering != LoginOfferings.email ? "two-login-offerings" : "one-login-offering";

  const giftReturnHandlers = handleEnterKeyPressOnClickHandlers(() => {
    ga.event({
      category: AnalyticCategories.LoginPage,
      action: LoginPageActions.StartHereForGiftFromEmailPage
    })
    setLoginMethod(LoginOfferings.giftReturn)
  });
  const orderReturnHandler = handleEnterKeyPressOnClickHandlers(() => {
    ga.event({
      category: AnalyticCategories.LoginPage,
      action: LoginPageActions.StartWithOrderIDFromEmailPage
    })
    setLoginMethod(loginOffering)
  });
  return (
    <>
      <form onSubmit={onSubmit}>
        <TextInput
          label={t("emailAddress")}
          value={loginState[LoginKeys.email] || ""}
          onChange={(val) => onLoginStateChange(LoginKeys.email, val)}
          forceTopScrollOnBlur
          dataCyString={DataCyStrings.emailAddressInput}
          disabledState={inputDisabledState}
        />
        <div className={offeringsClassName}>
          {loginOffering != LoginOfferings.email && (
            <div
              className="change-login-offering"
              {...orderReturnHandler}
              data-cy={DataCyStrings.startWithAnOrderIdButton}
            >
              {t("startWithAnOrderId")}
            </div>
          )}
          {hasGiftOffering && (
            <div>
              <Trans t={t} i18nKey="receivedAGift">
                Received a gift?
                <div className="change-login-offering" data-cy={DataCyStrings.giftReturnButton} {...giftReturnHandlers}>
                  Start Here
                </div>
              </Trans>
            </div>
          )}
        </div>
        <PrimaryButton
          label={t("emailLinkToStartYour")}
          disabled={!canSubmit}
          dataCyString={DataCyStrings.startYourReturnButton}
        />
      </form>
    </>
  );
};

const Banner = ({ info, localErrorMessage, error, success }) => {
  if (localErrorMessage) {
    return <ErrorAlertMessage message={localErrorMessage} />;
  } else if (error) {
    return <ErrorAlertMessage message={error} />;
  } else if (success) {
    return <SuccessMessage message={success} />;
  } else if (info) {
    return <NeutralMessage message={info} />;
  } else {
    return null;
  }
};

export default LoginReturnPortal;

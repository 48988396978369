import Statsig, { StatsigUser, DynamicConfig } from "statsig-js";
import logger from "./logger/logger";

export interface IFeatureFlag {
    isStatsigInitialized: boolean,
    initialize(user: StatsigClientUser),
    isEnabled(gate: string): boolean,
    getExperiment(name: string): DynamicConfig | null,
    getExperimentData(experiment: DynamicConfig | null): StatsigExperimentData,
    logEvent(eventName: string, value: string | null, custom?: Record<string, string> | null | undefined),
}

export enum statsigFeatureFlag {
    remove_multi_bag_prompt_for_single_item = "remove_multi_bag_prompt_for_single_item",
}

export enum statsigExperiment{
    dropoff_methods_claims_and_labels = "dropoff_methods_-_claims_and_labels"
}

export enum statsigExperimentValues{
    dropoff_methods_claims_and_labels_label = "label",
}

export enum statsigEvents{
    selected_dropoff_method = "selected_dropoff_method",
    dropoff_methods_claims_and_labels_experiment = "dropoff_methods_claims_and_labels_experiment"
}

export interface StatsigClientUser {
    retailerID?: string
    environment?: string
    email?: string,
    locale?: string,
}

export interface StatsigExperimentData {
    group?: string,
    blockedBy?: string
  }

class FeatureFlag implements IFeatureFlag {
    isStatsigInitialized = false
    async initialize(user: StatsigClientUser) {
        if (!user) return
        user.environment = user?.environment != "testing" && user.environment != "" ? user.environment : "development";
        const statsigUser = {
            userID: user?.email,
            statsigEnvironment: {
                tier: user.environment || ""
            },
            custom: {
              retailerID: user?.retailerID || "",
              locale: user?.locale || "",
            },
            customIDs: {
                retailerID: user?.retailerID || ""
            }
        }

        await Statsig.initialize(
            "client-fklBnDkSXBNX9wcBpXCDTBTpJmmXZXTgnXBEJFVzi8R",
            statsigUser
        ).catch((e) => {
            logger.Error("[StatsigClient] Error to initialize: " + e)
        }) .then(() => {
            this.isStatsigInitialized = true;
        });
    }

    isEnabled(gate: string): boolean {
        try {
            return Statsig.checkGate(gate)
        } catch(e) {
            logger.Error("[StatsigClient] Error to verify feature flag '" + gate + "': " + e)
            return false
        }
    }

    getExperiment(name: string): DynamicConfig | null {
        try {
            return Statsig.getExperiment(name);
        } catch (e) {
            logger.Error("[StatsigClient] Error to retrieve experiment '" + name + "': " + e)
            return null
        }
    }

    getExperimentData(experimentResult: DynamicConfig | null): StatsigExperimentData {
        if (!experimentResult) return {}
        return {
            group: experimentResult?.getGroupName() as string,
            blockedBy: defineExperimentBlockingReason(experimentResult?.getRuleID(), experimentResult?.getEvaluationDetails().reason),
            ...experimentResult?.value,
        }
    }

    logEvent(eventName: string, value: string | null, custom?: Record<string, string> | null | undefined) {
        if (eventName == "") {
            logger.Error("[StatsigClient] Log Event error: Event Name cannot be empty.");
            return
        }
        try {
            Statsig.logEvent(eventName, value, custom);
        } catch (e) {
            logger.Error("[StatsigClient] Error to log event: " + e);
        }
    }
}

const defineExperimentBlockingReason = (ruleID: string, evaluationReason: string): string =>  {
    if (ruleID === "targetingGate") return "Target Gate"
    else if (ruleID.includes(":id_override")) return "Override Rule"
    else if (evaluationReason === "Uninitialized") return "Statsig Fail to deliver experiment data"
    else return ""
}

export const statsigClient: IFeatureFlag = new FeatureFlag()